<input (typeaheadLoading)="changeTypeaheadLoading($event)"
       (typeaheadNoResults)="changeTypeaheadNoResults($event)"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"
       [(ngModel)]="asyncSelected"
       [typeaheadAsync]="true"
       [typeaheadItemTemplate]="typeaheadTemplate"
       [typeaheadOptionsLimit]="40"
       [typeaheadWaitMs]="100"
       [typeahead]="dataSource"
       class="form-control search-field"
       placeholder="{{placeholder}}"
       role="combobox"
       typeaheadOptionField="name"
>
@if (typeaheadLoading) {
  <fa-icon [icon]="faSpinner" animation="spin" class="typeahead-icon" size="lg"></fa-icon>
}
@if (!typeaheadLoading) {
  <fa-icon [icon]="faSearch" class="typeahead-icon" size="lg"></fa-icon>
}

<ng-template #typeaheadTemplate let-index="index" let-model="item">
  <div class="clickable">
    <div class="typeahead-image float-left">
      <img alt="{{model.name}}" src="{{model.imageURL}}"/>
    </div>

    @if (model.type === 'film') {
      <div>
        {{ model.name }} <span class="text-muted">({{ model.year }})</span>
        <div class="text-muted">
          @if (model.originalTitle !== model.name) {
            <small>{{ model.originalTitle }}</small>
          }
        </div>
      </div>
    }

    @if (model.type === 'person') {
      <div>
        {{ model.name }}
        <div class="text-muted">
          <small>
            {{ renderRoles(model) }}
          </small>
        </div>
      </div>
    }
  </div>
</ng-template>
