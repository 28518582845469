import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeaheadHit } from '../typeaheadHit';
import { TypeaheadService } from '../typeahead.service';
import { mergeMap } from 'rxjs/operators';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Observable, Subscriber } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FormsModule } from '@angular/forms';
import { faSearch, faSpinner } from "@fortawesome/pro-regular-svg-icons";

@Component({
  selector: 'vv-film-type-ahead',
  templateUrl: './film-type-ahead.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, TypeaheadModule, FontAwesomeModule]
})
export class FilmTypeAheadComponent implements OnInit {

  public asyncSelected!: string | undefined;
  public dataSource: Observable<TypeaheadHit>;
  @Input()
  includeFilms = true;
  @Input()
  includePersons = false;
  placeholder!: string;
  @Output() public selected: EventEmitter<any> = new EventEmitter();
  public typeaheadLoading!: boolean;
  public typeaheadNoResults!: boolean;
  protected readonly faSpinner = faSpinner;
  protected readonly faSearch = faSearch;

  public constructor(private typeaheadService: TypeaheadService) {
    this.dataSource = new Observable((observer: Subscriber<string>) => {
      // Runs on every search
      observer.next(this.asyncSelected);
    }).pipe(
      mergeMap((token: string) => this.getStatesAsObservable(token))
    );
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public getStatesAsObservable(token: string): Observable<TypeaheadHit> {
    return this.typeaheadService.search(token, this.includeFilms, this.includePersons);
  }

  ngOnInit(): void {
    this.placeholder = this._placeholder();
  }

  renderRoles(person: TypeaheadHit) {
    const roles = [];
    if (person.director) {
      roles.push('regissör');
    }
    if (person.actor) {
      roles.push('skådespelare');
    }
    if (person.writer) {
      roles.push('författare');
    }
    return roles.join(' | ');
  }

  public typeaheadOnSelect(e: TypeaheadMatch): void {
    this.asyncSelected = undefined;
    this.selected.emit(e.item);
  }

  private _placeholder(): string {
    if (this.includeFilms && !this.includePersons) {
      return 'Sök filmtitel';
    } else if (this.includePersons && !this.includeFilms) {
      return 'Sök person';
    }
    return 'Sök filmtitel eller person';
  }
}
